<template>
  <div class="supper">
    <div class="holder">
      <!-- <AuthNavbar /> -->
      <!-- <div class="mobile-logo-holder">
        <div class="blue-back-logo">
          <img src="../assets/images/logo-header.svg" alt="" />
        </div>
        <div class="shelta-mobile-logo">
          <img src="../assets/images/logo1.svg" alt="" />
        </div>
      </div> -->
      <div class="login-body">
        <div class="container login-wrap">
          <div class="logo_holder">
            <img src="../assets/images/logo-yellow.svg" alt="" />
          </div>
          <div class="card">
            <div class="card-body my-card-body">
              <div class="login-header">
                <h1>Recover Password</h1>
              </div>
              <form>
                <div class="form-group password-group">
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="New password"
                    autocomplete="on"
                    name="password"
                    v-model="password"
                  />
                  <i
                    class="fa fa-lock password-icon"
                    id="passwordVisibility"
                    aria-hidden="true"
                    @click="toggleLock()"
                  ></i>
                </div>
                <div class="form-group password-group2">
                  <input
                    type="password"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    autocomplete="on"
                    name="confirmPassword"
                    v-model="confirmPassword"
                  />
                  <i
                    class="fa fa-lock password-icon2"
                    id="passwordVisibility2"
                    aria-hidden="true"
                    @click="toggleLock2()"
                  ></i>
                </div>
                <div v-if="errors.length">
                  <p class="errorHeader">Please correct the error(s):</p>
                  <ul class="error-ul">
                    <li
                      v-for="error in errors"
                      :key="error.error"
                      class="errorMessage"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
                <div class="form-group">
                  <button
                    @click.prevent="recoverPassword()"
                    class="btn login-btn btn-block"
                  >
                    Recover
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="no-account-password">
            <p>
              <router-link class="register-router" to="/signup">
                Don't have an account?
                <span class="register-span">Register</span>
              </router-link>
            </p>
            <p>Have an account? <span class="have-account"><router-link class="login-router" to="/login">Login</router-link></span></p>
          </div> -->
        </div>
        <!-- <div class="whatsapp-dialer-icon-holder">
          <img src="../assets/images/whatsapp-icon.svg" alt="whatsapp" />
          <img src="../assets/images/phone-handle.svg" alt="call us" />
        </div> -->
      </div>
      <!-- Footter bigins -->

      <!-- Footer ends -->
    </div>
    <!-- Footter bigins -->
    <Footer />
    <!-- Footer ends -->
  </div>
</template>

<script>
import Footer from "./FooterMain2.vue";
// import AuthNavbar from "./AuthNavbar";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "RecoverPassword",
  components: {
    // Header,
    Footer
    // AuthNavbar,
  },
  data() {
    return {
      loader: false,
      errors: [],
      password: "",
      confirmPassword: ""
    };
  },
  methods: {
    recoverPassword: function() {
      this.loader = true;
      this.errors = [];
      if (this.password === "") {
        this.errors.push("Password is required!");
        this.loader = false;
      } else if (this.password != this.confirmPassword) {
        this.errors.push("Passwords don't match");
        this.loader = false;
      } else {
        const tokencode = this.$route.params.token;
        Api.postRequestNoAuth("saveresetpassword", {
          recoveryCode: tokencode,
          password: this.password
        })
          .then(res => {
            console.log("Login Response", res);
            this.loader = false;
            if (res.data.success) {
              this.$store.commit("setApiSuccess", res.data.success);
              this.$router.push({
                path: `/login`
              });
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    // Password visibilty
    toggleLock: function() {
      const element = document.querySelector(".password-icon");
      this.shown = !this.shown;
      if (this.shown) {
        document.getElementsByName("password")[0].setAttribute("type", "text");
        element.classList.remove("fa-lock");
        element.classList.add("fa-unlock");
      } else {
        document
          .getElementsByName("password")[0]
          .setAttribute("type", "password");
        element.classList.remove("fa-unlock");
        element.classList.add("fa-lock");
      }
    },
    // Confirm Password visibilty2
    toggleLock2: function() {
      const element = document.querySelector(".password-icon2");
      this.shown = !this.shown;
      if (this.shown) {
        document
          .getElementsByName("confirmPassword")[0]
          .setAttribute("type", "text");
        element.classList.remove("fa-lock");
        element.classList.add("fa-unlock");
      } else {
        document
          .getElementsByName("confirmPassword")[0]
          .setAttribute("type", "password");
        element.classList.remove("fa-unlock");
        element.classList.add("fa-lock");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");

::-webkit-input-placeholder {
  /* Edge */
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

::placeholder {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  // min-height: 100vh;
}

.logo_holder {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.logo-div {
  background: $primary;
  // clip-path: circle(50% at 50% 0);
  clip-path: circle(50% at 50% -20%);
  margin: auto;
  width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
}
.logo-div > img {
  width: 4.5rem;
  position: relative;
  top: 23%;
}

.my-card-body {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.login-header {
  h1 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }
}

.login-body {
  position: relative;
  padding-top: 6rem;
}

.login-body > div {
  // width: 25rem;
  margin: auto;
}

.login-btn {
  background-color: $primary;
  color: #ffffff;
  text-transform: uppercase;
}

.no-account-password {
  display: flex;
  justify-content: space-between;
  padding-top: 0.6rem;
}
.no-account-password {
  p {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
  }
}

.register-span {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;
  color: $primary;
}
.register-router {
  color: #000000;
  text-decoration: none;
}
.login-router {
  color: $primary;
  text-decoration: none;
  font-weight: bold;
}

// .login-wrap {
//   display: flex;
// }

.password-group {
  position: relative;
}
.password-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 3% !important;
}

.password-group2 {
  position: relative;
}
.password-icon2 {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 3% !important;
}
.password-icon::before,
.password-icon2::before {
  cursor: pointer;
}

.whatsapp-dialer-icon-holder {
  position: relative;
}
.whatsapp-dialer-icon-holder > img:first-child {
  position: relative;
  bottom: 12rem;
  left: 40rem;
}
.whatsapp-dialer-icon-holder > img:nth-child(2) {
  position: relative;
  bottom: 8rem;
  left: 38.4rem;
}

.errorHeader {
  font-family: $fontFamily;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}
.errorMessage {
  font-family: $fontFamily;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

.btn-loader {
  padding: 0 !important;
  font-size: 17px !important;
  margin-left: 2rem !important;
}

// Footer section begins
.footer-holder {
  position: relative;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 13.55px !important;
  height: 13.55px !important;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  position: relative;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  // background: #3b5998;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-facebook::before {
  position: absolute;
  left: 38%;
  top: 30%;
  font-size: 1.4rem;
}

.fa-twitter {
  // background: #55acee;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-twitter::before {
  position: absolute;
  left: 30%;
  top: 28%;
  font-size: 1.4rem;
}

.fa-linkedin {
  // background: #007bb5;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-linkedin::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}

.fa-instagram {
  // background: #125688;
  border: 1px solid #fff;
  color: white;
  border-radius: 3px;
}
.fa-instagram::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}
.clip-shape-div {
  clip-path: ellipse(43% 164% at 50% 164%);
  // clip-path: ellipse(35% 164% at 50% 165%);
  background: $primary;
  // position: relative;
}

.copy-right-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  color: #ffffff;
  padding-bottom: 0.5rem;
}

.help-holder {
  position: absolute;
  right: 2%;
  bottom: 8%;
  width: 89px;
  height: 45px;
  background: $primary;
  border-radius: 22.5px;
}
// Footer section ends

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .login-body {
    padding-top: 0;
  }
  .whatsapp-dialer-icon-holder {
    // position: relative;
    // right: 22%;

    right: 45%;
    justify-content: center;
    margin-bottom: 2rem;
    padding-top: 0.6rem;
  }
  .no-account-password {
    display: block;
  }
  .whatsapp-dialer-icon-holder > img:first-child {
    bottom: 1rem;
    left: 7px;
  }
  .whatsapp-dialer-icon-holder > img:nth-child(2) {
    bottom: 1rem;
    left: 9px;
  }
  .footer-holder {
    padding-top: 7rem;
  }
  .help-holder {
    left: 36%;
    top: 23%;
    position: absolute;
  }
}
@media only screen and (max-width: 599px) {
  .supper {
    min-height: 100%;
  }
  .logo_holder {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  .footer-holder {
    margin-top: 7.1rem;
  }
  .mobile-logo-holder {
    margin: auto;
    height: 4rem;
    position: relative;
  }
  .blue-back-logo > img {
    width: 18.3016105417vw;
  }
  .shelta-mobile-logo {
    position: absolute;
    top: 5%;
    left: 46%;
  }
  .shelta-mobile-logo > img {
    width: 8.7115666179vw;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .login-body > div {
    max-width: 29.02625rem !important;
  }
  .mobile-logo-holder {
    display: none;
  }
}
</style>
